import React, { useEffect, useContext, useState } from "react";
import Profil from "../Components/Profil";
import { FormContext } from "../../shared/context/form-context";
import "./Psy.css";
import { useNavigate } from "react-router-dom";
const Psy = () => {
  const form = useContext(FormContext);
  const [loadedPsy, setLoadedPsy] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const dataFetch = async () => {
      let response;
      let data;
      if (form.mail === "") {
        navigate("/a");
      } else {
        if (loadedPsy.length === 0) {
          try {
            try {
              response = await fetch(
                "https://upcare-app-93359469293a.herokuapp.com/psy/",
                {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(form),
                }
              );
              data = await response.json();
              setLoadedPsy(data.persons);
              var names = "";
              for (var i = 0; i < data.persons.length; i++) {
                console.log("test" + i);
                console.log(data.persons);
                names =
                  names +
                  "-" +
                  data.persons[i].Nom +
                  " " +
                  data.persons[i].Prénom;
              }
            } catch (error) {
              console.log(error);
            }
            response = await fetch(
              "https://upcare-app-93359469293a.herokuapp.com/form/",
              {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  mail: form.mail,
                  age: form.years,
                  sexe: form.sexe,
                  postal: form.postal,
                  type: form.type,
                  psy: names,
                  dure: form.dure,
                }),
              }
            );
            const responseData = await response.json();
            console.log(responseData);
          } catch (error) {
            console.log(error);
          }
        }
      }
    };
    dataFetch();
  }, []);
  return (
    <div className="wrapper-psys">
      {loadedPsy &&
        loadedPsy.map((psy) => {
          return (
            <Profil
              key={psy.Nom}
              name={psy["Prénom"] + " " + psy["Nom"]}
              image={psy["Photo de profil"]["0"].url}
              tags={psy["Spécialités & Thérapies"]}
              adress={psy["Adresse"]}
              lgbt={psy["LGBT+ friendly"]}
              handi={psy["Acces handicapé"]}
              link={psy["Lien site UpCare"]}
              remote={psy["Téléconsultation"]}
            ></Profil>
          );
        })}
    </div>
  );
};

export default Psy;
