import React, { useContext, useState } from "react";
import Input from "../../shared/components/Input";
import "./../Individuel/Reasons.css";
import { FormContext } from "../../shared/context/form-context";
const ReasonsC = (props) => {
  const [option, setOption] = useState(false);
  const form = useContext(FormContext);
  return (
    <div className={props.step === 2 ? "vert-wrapper" : "hidden"}>
      <h2>
        Super ! Maintenant, laissez-vous porter et faites nous confiance pour
        trouver le praticien fait pour vous
      </h2>
      <p>Pourquoi souhaitez-vous consulter ? *</p>
      <div className={option === false ? "hor-wrapper" : "hidden"}>
        <Input
          nature="check"
          id="Problèmes de communication"
          name="Problèmes de communication"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Apprendre à communiquer efficacement"
        ></Input>
        <Input
          nature="check"
          id="Problèmes dans nos relations intimes"
          name="Problèmes dans nos relations intimes"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Comprendre un déséquilibre dans nos relations intimes"
        ></Input>
        <Input
          nature="check"
          id="Problèmes dans nos relations intimes"
          name="Problèmes dans nos relations intimes"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="Trouver l’équilibre dans nos relations intimes"
        ></Input>
        <Input
          nature="check"
          id="Gestion d'une infidélité"
          name="Gestion d'une infidélité"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Faire face à une infidélité"
        ></Input>
        <Input
          nature="check"
          id="Gestion du stress financier"
          name="Gestion du stress financier"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Faire face au stress financier"
        ></Input>
      </div>
      <div className={option === true ? "hor-wrapper" : "hidden"}>
        <Input
          nature="check"
          id="Parentalité"
          name="Parentalité"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Nous aider à allier parentalité et couple"
        ></Input>
        <Input
          nature="check"
          id="Manque de confiance en l'autre"
          name="Manque de confiance en l'autre"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Nous aider à rétablir la confiance en l’autre"
        ></Input>
        <Input
          nature="check"
          id="Manque de confiance en l'autre"
          name="Manque de confiance en l'autre"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="⁠Nous aider à rétablir la confiance"
        ></Input>
        <Input
          nature="check"
          id="Autre"
          name="Autre"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Autre"
        ></Input>
        <Input
          nature="check"
          id="Préfère ne pas dire"
          name="Préfère ne pas dire"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Préfère ne pas dire"
        ></Input>
      </div>
      <p
        className="other"
        onClick={(event) => {
          event.preventDefault();
          setOption(!option);
        }}
      >
        Charger de nouvelles options
      </p>
    </div>
  );
};

export default ReasonsC;
