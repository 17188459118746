import React, { useState } from "react";
import "./Navbar.css";
import Button from "./Button";
const Navbar = () => {
  return (
    <nav className="nav">
      <a href="https://up-care.fr">
        <img
          src={require("./../../public/images/4.png")}
          className="nav-logo"
        ></img>
      </a>
    </nav>
  );
};
export default Navbar;
