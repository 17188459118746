import React, { useState } from "react";
import "./Input.css";
const Input = (props) => {
  const [toggle, setToggle] = useState(false);

  const handler = () => {
    setToggle(!toggle);
  };
  return (
    <div className={props.textDes ? "none" : "auto"}>
      {props.nature === "field" && props.req && (
        <input
          className={props.width === "small" ? "input" : "input large"}
          type={props.type}
          id={props.id}
          onChange={props.handler}
          value={props.value}
          placeholder={props.placeholder}
        ></input>
      )}
      {props.nature === "field" && !props.req && (
        <input
          className={props.width === "small" ? "input" : "input large"}
          type={props.type}
          id={props.id}
          onChange={props.handler}
          value={props.value}
          placeholder={props.placeholder}
        ></input>
      )}
      {props.nature === "check" && (
        <div
          className={
            props.text
              ? "check-wrapper"
              : props.textMob
              ? "check-wrapper mobile"
              : "check-wrapper desktop"
          }
        >
          <input
            id={props.id}
            name={props.name}
            type="checkbox"
            onChange={props.change}
            className="checkbox"
          ></input>
          <label
            for={props.id}
            className={toggle === true ? "checked" : "to-check"}
            onClick={handler}
          >
            {props.text}
            {props.textMob}
            {props.textDes}
          </label>
        </div>
      )}
      {props.nature === "select" && (
        <select
          name={props.name}
          id={props.id}
          className={props.w !== "long" ? "select" : "select-large"}
          onChange={props.handler}
        >
          <option value="">{props.question}</option>
          {props.options.map((opt) => {
            return (
              <option key={opt.id} value={opt.resp}>
                {opt.resp}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export default Input;
