import React from "react";
import Button from "../../shared/components/Button";
import "./Buttons.css";

const Buttons = (props) => {
  return (
    <div className="wrapper-buttons">
      <div>
        {props.step > 1 && (
          <Button text="Précédent" click={props.previous}></Button>
        )}
      </div>
      {props.step < props.lim ? (
        <Button text="Suivant" click={props.next}></Button>
      ) : (
        <Button text="Matchez" sub="true"></Button>
      )}
    </div>
  );
};

export default Buttons;
