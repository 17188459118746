import React, { useContext } from "react";
import "./Home.css";
import { FormContext } from "../../shared/context/form-context";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();
  const form = useContext(FormContext);
  return (
    <div className="home-wrapper">
      <h2>De qui souhaitez-vous prendre soin ?</h2>
      <div className="link-hor-wrapp">
        <div
          className="type-link"
          onClick={() => {
            form.setType("Individuel");
            navigate("/matching");
          }}
        >
          <img
            className="icon-link"
            alt="Individu"
            src={require("./../../public/images/INDIVIDUELS.png")}
          ></img>
          <p>De moi-même</p>
        </div>
        <div
          className="type-link"
          href="#"
          onClick={() => {
            form.setType("Enfant");
            navigate("/matching");
          }}
        >
          <img
            className="icon-link"
            alt="Individu"
            src={require("./../../public/images/ENFANTS.png")}
          ></img>
          <p>De mon enfant</p>
        </div>
        <div
          className="type-link"
          href="#"
          onClick={() => {
            form.setType("Couple");
            navigate("/matching");
          }}
        >
          <img
            className="icon-link"
            alt="Individu"
            src={require("./../../public/images/COUPLE (1).png")}
          ></img>
          <p>De mon couple</p>
        </div>
      </div>
    </div>
  );
};

export default Home;
