import { createContext } from "react";

export const FormContext = createContext({
  type: "",
  mail: "",
  address: "",
  postal: "",
  years: "",
  sexe: "",
  feelings: [],
  why: [],
  wait: [],
  countfeelings: {},
  countWhy: {},
  countWait: {},
  trust: "",
  remote: "",
  handi: "",
  budget: "",
  like: "",
  lgbt: "",
  dure: "",
  valid: false,
});
