import React, { useContext } from "react";
import Formulaire from "../Individuel/Formulaire";
import { FormContext } from "../../shared/context/form-context";
import FormulaireE from "../Enfant/FormulaireE";
import "./Matching.css";
import FormulaireC from "../Couple/FormulaireC";
const Matching = () => {
  const form = useContext(FormContext);

  return (
    <div className="wrapperformu">
      <div className="formwrapper">
        {form.type === "Individuel" && <Formulaire />}
        {form.type === "Enfant" && <FormulaireE />}
        {form.type === "Couple" && <FormulaireC />}
      </div>
      <div className="wrapp-leftimg">
        <img
          alt="decoration"
          className="img-form"
          src={require("./../../public/images/Citation 1.png")}
        />
      </div>
    </div>
  );
};

export default Matching;
