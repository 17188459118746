import React from "react";
import Tag from "./Tag";
import "./Profil.css";
const Profil = (props) => {
  return (
    <div className="wrapper-profil">
      <img src={props.image} alt="profil" className="profil"></img>
      <h2 className="name">{props.name}</h2>
      {props.tags &&
        props.tags.map((tag) => {
          return <Tag key={tag} text={tag} spe="yes"></Tag>;
        })}
      {props.remote && <Tag text="Téléconsultation" spe="no"></Tag>}
      <p className="name">{props.adress}</p>

      <div className="bottom">
        <a className="link-psy" href={props.link} target="_blank">
          En savoir plus
        </a>
      </div>
      <div className="icons">
        {props.handi && (
          <img
            alt="label handicapé"
            className="little-icon"
            src={require("./../../public/images/Label accès handicapé.png")}
          ></img>
        )}
        {props.lgbt && (
          <img
            alt="label lgbt"
            className="little-icon"
            src={require("./../../public/images/Label LGBT.png")}
          ></img>
        )}
      </div>
    </div>
  );
};

export default Profil;
