import React, { useState, useContext } from "react";
import "./Formulaire.css";
import Step from "./Step";
import Buttons from "../Components/Buttons";
import { FormContext } from "../../shared/context/form-context";
import { useNavigate } from "react-router-dom";
import Individuel from "./Individuel";

const Formulaire = () => {
  const form = useContext(FormContext);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const handleNext = (event) => {
    event.preventDefault();
    setStep(step + 1);
    console.log(step);
  };
  const handlePrevious = (event) => {
    event.preventDefault();
    setStep(step - 1);
    console.log(step);
  };
  const subHandler = async (event) => {
    event.preventDefault();
    console.log(form);
    if (
      form.feelings.length >= 1 &&
      form.wait.length >= 1 &&
      form.why.length >= 1 &&
      form.valid === true
    ) {
      navigate("/recommendations");
    } else {
      setError(true);
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={subHandler}>
        <div className="vertical-wrapper">
          <Step step={step}></Step>
          {/* <Type step={step}></Type> */}
          {/* {form.type === "Individuel" && <Individuel step={step}></Individuel>} */}
          <Individuel step={step}></Individuel>
          <Buttons
            next={handleNext}
            previous={handlePrevious}
            step={step}
            lim={7}
          ></Buttons>
          {error && (
            <p className="red">
              Veuillez répondre a toutes les questions obligatoires
            </p>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default Formulaire;
