import React, { useContext } from "react";
import Input from "../../shared/components/Input";
import "./Feeling.css";
import { FormContext } from "../../shared/context/form-context";
const Feeling = (props) => {
  const form = useContext(FormContext);
  return (
    <div className={props.step === 2 ? "vert-wrapper" : "hidden"}>
      <h2>
        Super ! Maintenant, laissez-vous porter et faites nous confiance pour
        trouver le praticien fait pour vous
      </h2>
      <p>Comment vous sentez-vous ? *</p>
      <div className="ho-wrapper">
        <Input
          nature="check"
          id="Je me sens plutôt bien"
          name="Je me sens plutôt bien"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          text="Je me sens plutôt bien"
        ></Input>
        <Input
          nature="check"
          id="Je me sens stressée"
          name="Je me sens stressée"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          text="Je me sens stressée"
        ></Input>
        <Input
          nature="check"
          id="Je me sens déprimé.e"
          name="Je me sens déprimé.e"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          text="Je me sens déprimé.e"
        ></Input>
        <Input
          nature="check"
          id="Je me sens anxieux.se et submergé.e"
          name="Je me sens anxieux.se et submergé.e"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          text="Je me sens anxieux.se et submergé.e"
        ></Input>
        <Input
          nature="check"
          id="Je ne vais pas bien mais je ne sais pas pourquoi"
          name="Je ne vais pas bien mais je ne sais pas pourquoi"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          textDes="Je ne vais pas bien mais je ne sais pas pourquoi"
        ></Input>
        <Input
          nature="check"
          id="Je ne vais pas bien mais je ne sais pas pourquoi"
          name="Je ne vais pas bien mais je ne sais pas pourquoi"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          textMob="Je ne me sens pas bien sans raison"
        ></Input>
        <Input
          nature="check"
          id="Je me sens épuisé.e"
          name="Je me sens épuisé.e"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          text="Je me sens épuisé.e"
        ></Input>
        <Input
          nature="check"
          id="Autre"
          name="Autre"
          change={(event) => {
            form.setFeelings(form.feelings.concat(event.target.id));
          }}
          text="Autre"
        ></Input>
      </div>
    </div>
  );
};

export default Feeling;
