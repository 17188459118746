import React from "react";
import Personal from "./Personal";
import Feeling from "./Feeling";
import Reasons from "./Reasons";
import Consulted from "./Consulted";
import Complementary from "../Components/Complementary";
import Adress from "../Components/Adress";
import Perso from "../Components/Perso";
const Individuel = (props) => {
  return (
    <div>
      <Personal step={props.step}></Personal>
      <Feeling step={props.step}></Feeling>
      <Reasons step={props.step}></Reasons>
      <Consulted step={props.step}></Consulted>
      <Complementary step={props.step} lim={5}></Complementary>
      <Adress step={props.step} lim={6}></Adress>
      <Perso step={props.step} lim={7}></Perso>
    </div>
  );
};

export default Individuel;
