import React from "react";
import "./Step.css";
const Step = (props) => {
  return (
    <div className="grid-wrapper">
      <div className="one orange"></div>
      <div className={props.step >= 2 ? "two orange" : "two grey"}></div>
      <div className={props.step >= 3 ? "three orange" : "three grey"}></div>
      <div className={props.step >= 4 ? "four orange" : "four grey"}></div>
      <div className={props.step >= 5 ? "five orange" : "five grey"}></div>
      <div className={props.step >= 6 ? "six orange" : "six grey"}></div>
      <div className={props.step >= 7 ? "seven orange" : "seven grey"}></div>
    </div>
  );
};

export default Step;
