import React, { useContext } from "react";
import Input from "../../shared/components/Input";
import { FormContext } from "../../shared/context/form-context";

const PersonalChild = (props) => {
  const form = useContext(FormContext);
  return (
    <span>
      <div className={props.step === 1 ? "vert-wrapper" : "hidden"}>
        <h2>
          Notre objectif est de comprendre votre besoin pour y répondre au mieux
        </h2>
        <p>
          Pas de panique ça ne prendre que 5 min et vos réponses seront ensuite
          directement supprimées pour protéger votre intimité
        </p>
        <Input
          width="small"
          nature="field"
          placeholder="Age de l'enfant *"
          type="number"
          handler={(e) => form.setYears(e.target.value)}
          id="years"
          value={form.years}
        ></Input>
        <Input
          nature="select"
          name="Sexe"
          question="Êtes-vous ? *"
          options={[
            { id: "Femme", resp: "Femme" },
            { id: "Homme", resp: "Homme" },
            { id: "Autre", resp: "Autre" },
            { id: "Préfère ne pas dire", resp: "Préfère ne pas dire" },
          ]}
          placeholder="Sexe *"
          type="select"
          handler={(e) => form.setSexe(e.target.value)}
          id="sexe"
          value={form.sexe}
        ></Input>
      </div>
    </span>
  );
};

export default PersonalChild;
