import React, { useState, useContext } from "react";
import "./../Individuel/Formulaire.css";
import StepE from "./StepE";
import Buttons from "../Components/Buttons";
import { FormContext } from "../../shared/context/form-context";
import { useNavigate } from "react-router-dom";
import PersonalChild from "./PersonalChild";
import ReasonsE from "./ReasonsE";
import Complementary from "../Components/Complementary";
import Adress from "../Components/Adress";
import Perso from "../Components/Perso";
const FormulaireE = () => {
  const form = useContext(FormContext);
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [error, setError] = useState(false);
  const handleNext = (event) => {
    event.preventDefault();
    setStep(step + 1);
    console.log(step);
  };
  const handlePrevious = (event) => {
    event.preventDefault();
    setStep(step - 1);
    console.log(step);
  };
  const subHandler = async (event) => {
    event.preventDefault();
    console.log(form);
    if (form.why.length >= 1) {
      navigate("/recommendations");
    } else {
      setError(true);
    }
  };
  return (
    <React.Fragment>
      <form onSubmit={subHandler}>
        <div className="vertical-wrapper">
          <StepE step={step}></StepE>
          <PersonalChild step={step}></PersonalChild>
          <ReasonsE step={step}></ReasonsE>
          <Complementary step={step} lim={3}></Complementary>
          <Adress step={step} lim={4}></Adress>
          <Perso step={step} lim={5}></Perso>

          <Buttons
            next={handleNext}
            previous={handlePrevious}
            step={step}
            lim={5}
          ></Buttons>
          {error && (
            <p className="red">
              Veuillez répondre a toutes les questions obligatoires
            </p>
          )}
        </div>
      </form>
    </React.Fragment>
  );
};

export default FormulaireE;
