import React, { useContext, useState } from "react";
import Input from "../../shared/components/Input";
import "./Reasons.css";
import { FormContext } from "../../shared/context/form-context";
const Reasons = (props) => {
  const [option, setOption] = useState(false);
  const form = useContext(FormContext);
  return (
    <div className={props.step === 3 ? "vert-wrapper" : "hidden"}>
      <p>Pourquoi souhaitez-vous consulter ? *</p>
      <div className={option === false ? "hor-wrapper" : "hidden"}>
        <Input
          nature="check"
          id="Prendre confiance en moi"
          name="Prendre confiance en moi"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Prendre confiance en moi"
        ></Input>
        {/* Desktop */}
        <Input
          nature="check"
          id="Apprendre à construire et maintenir des relations"
          name="Apprendre à construire et maintenir des relations"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Apprendre à construire et maintenir des relations"
        ></Input>
        {/* Mobile */}
        <Input
          nature="check"
          id="Apprendre à construire et maintenir des relations"
          name="Apprendre à construire et maintenir des relations"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="Apprendre à construire des relations"
        ></Input>
        {/* Desktop */}
        <Input
          nature="check"
          id="Être accompagner pour faire mon deuil"
          name="Être accompagner pour faire mon deuil"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Être accompagner pour faire mon deuil"
        ></Input>
        {/* Mobile */}
        <Input
          nature="check"
          id="Être accompagner pour faire mon deuil"
          name="Être accompagner pour faire mon deuil"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="Être accompagné dans mon deuil"
        ></Input>
        <Input
          nature="check"
          id="Accepter et gérer un traumatisme"
          name="Accepter et gérer un traumatisme"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="M’aider à faire face à un traumatisme"
        ></Input>
        {/* Desktop */}
        <Input
          nature="check"
          id="Améliorer et comprendre mes habitudes et troubles alimentaires"
          name="Améliorer et comprendre mes habitudes et troubles alimentaires"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Comprendre mes troubles alimentaires"
        ></Input>
        {/* Mob */}
        <Input
          nature="check"
          id="Améliorer et comprendre mes habitudes et troubles alimentaires"
          name="Améliorer et comprendre mes habitudes et troubles alimentaires"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="Comprendre mes troubles alimentaires"
        ></Input>
        <Input
          nature="check"
          id="Me libérer d'une addiction"
          name="Me libérer d'une addiction"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Être accompagné face à mon addiction"
        ></Input>
        <Input
          nature="check"
          id="Comprendre mes troubles du sommeil"
          name="Comprendre mes troubles du sommeil"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Comprendre mes troubles du sommeil"
        ></Input>
      </div>
      <div className={option === true ? "hor-wrapper" : "hidden"}>
        <Input
          nature="check"
          id="Fixer et atteindre mes objectifs"
          name="Fixer et atteindre mes objectifs"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Fixer et atteindre mes objectifs"
        ></Input>
        {/* Desktop */}
        <Input
          nature="check"
          id="M'aider à trouver mon identité/orientation sexuelle"
          name="M'aider à trouver mon identité/orientation sexuelle"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="M'aider à trouver mon identité/orientation sexuelle"
        ></Input>
        {/* Mobile */}
        <Input
          nature="check"
          id="M'aider à trouver mon identité/orientation sexuelle"
          name="M'aider à trouver mon identité/orientation sexuelle"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="Trouver mon identité/orientation sexuelle"
        ></Input>
        <Input
          nature="check"
          id="Gagner en concentration"
          name="Gagner en concentration"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Gagner en concentration"
        ></Input>
        <Input
          nature="check"
          id="M'aider à gérer un problème familial"
          name="M'aider à gérer un problème familial"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Y voir plus clair dans un problème familial"
        ></Input>
        <Input
          nature="check"
          id="M'aider à gérer un problème familial"
          name="M'aider à gérer un problème familial"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="⁠Comprendre un problème familial"
        ></Input>
        <Input
          nature="check"
          id="Soigner un/des TOC"
          name="Soigner un/des TOC"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Soigner un/des TOC"
        ></Input>
        <Input
          nature="check"
          id="Faire face à un grand changement"
          name="Faire face à un grand changement"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Faire face à un grand changement"
        ></Input>
        <Input
          nature="check"
          id="Bien m’orienter professionnellement"
          name="Bien m’orienter professionnellement"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Bien m’orienter professionnellement"
        ></Input>
      </div>
      <p
        className="other"
        onClick={(event) => {
          event.preventDefault();
          setOption(!option);
        }}
      >
        Charger de nouvelles options
      </p>
    </div>
  );
};

export default Reasons;
