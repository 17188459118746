import "./App.css";
import React, { useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate,
} from "react-router-dom";
import Navbar from "./shared/components/Navbar";
import "./public/fonts/Montserrat-Medium.ttf";
import Matching from "./user/pages/Matching";
import { FormContext } from "./shared/context/form-context";
import Psy from "./user/pages/Psy";
import Home from "./user/pages/Home";
const Layout = () => {
  return (
    <>
      <Navbar></Navbar>
      <Outlet></Outlet>
    </>
  );
};

const router = createBrowserRouter([
  {
    element: <Layout></Layout>,
    // errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/matching",
        element: <Matching />,
      },
      {
        path: "/recommendations",
        element: <Psy />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);
function App() {
  const [mail, setMail] = useState("");
  const [valid, setValid] = useState(false);
  const [dure, setDure] = useState("no");
  const [address, setAddress] = useState("");
  const [postal, setPostal] = useState("");
  const [years, setYears] = useState("");
  const [sexe, setSexe] = useState("");
  const [why, setWhy] = useState([]);
  const [wait, setWait] = useState([]);
  const [feelings, setFeelings] = useState([]);
  const [consulted, setConsulted] = useState("");
  const [experimentation, setExperimentation] = useState("");
  const [trust, setTrust] = useState("");
  const [remote, setRemote] = useState("");
  const [handi, setHandi] = useState("");
  const [budget, setBudget] = useState("");
  const [like, setLike] = useState("");
  const [type, setType] = useState("Enfant");
  const [countfeelings, setCountfeelings] = useState({});
  const [countWhy, setCountWhy] = useState({});
  const [countWait, setCountWait] = useState({});
  const [lgbt, setLgbt] = useState("");
  return (
    <FormContext.Provider
      value={{
        lgbt,
        setLgbt,
        countfeelings,
        setCountfeelings,
        countWhy,
        setCountWhy,
        countWait,
        setCountWait,
        mail,
        setMail,
        address,
        setAddress,
        postal,
        setPostal,
        dure,
        setDure,
        years,
        setYears,
        sexe,
        setSexe,
        why,
        setWhy,
        feelings,
        setFeelings,
        consulted,
        setConsulted,
        experimentation,
        setExperimentation,
        trust,
        setTrust,
        remote,
        setRemote,
        handi,
        setHandi,
        budget,
        setBudget,
        like,
        setLike,
        wait,
        setWait,
        type,
        setType,
        valid,
        setValid,
      }}
    >
      <main className="App">
        <RouterProvider router={router} />
      </main>
    </FormContext.Provider>
  );
}

export default App;
