import React from "react";
import "./Tag.css";
const Tag = (props) => {
  return (
    <div className={props.spe === "yes" ? "wrapper-tag" : "wrapper-tag-remote"}>
      <p className="tag">{props.text}</p>
    </div>
  );
};

export default Tag;
