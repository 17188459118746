import React, { useContext } from "react";
import "./Complementary.css";
import Input from "../../shared/components/Input";
import { FormContext } from "../../shared/context/form-context";

const Complementary = (props) => {
  const form = useContext(FormContext);
  return (
    <div className={props.step === props.lim ? "vert-wrapper" : "hidden"}>
      <Input
        w="long"
        nature="select"
        name="remote"
        question="Envisagez-vous de faire de la téléconsultation ? *"
        options={[
          { id: "Oui", resp: "Oui" },
          { id: "Non", resp: "Non" },
        ]}
        placeholder="Envisagez-vous de faire de la téléconsultation ? *"
        type="select"
        id="remote"
        value={form.remote}
        handler={(e) => form.setRemote(e.target.value)}
      ></Input>
      <Input
        w="long"
        nature="select"
        name="handi"
        question="Avez-vous besoin d’un accès handicapé ? *"
        options={[
          { id: "Oui", resp: "Oui" },
          { id: "Non", resp: "Non" },
          {
            id: "Je ne compte pas me déplacer",
            resp: "Je ne compte pas me déplacer",
          },
        ]}
        placeholder="Avez-vous besoin d’un accès handicapé ? *"
        type="select"
        id="handi"
        value={form.handi}
        handler={(e) => form.setHandi(e.target.value)}
      ></Input>
      <Input
        w="long"
        nature="select"
        name="budget"
        question="Quel est votre budget par séance ? *"
        options={[
          { id: "Inférieur à 40 euros", resp: "Inférieur à 40 euros" },
          { id: "Jusqu'à 60 euros", resp: "Jusqu'à 60 euros" },
          { id: "Jusqu'à 70 euros", resp: "Jusqu'à 70 euros" },
          { id: "Jusqu'à 80 euros", resp: "Jusqu'à 80 euros" },
          { id: "Peu importe", resp: "Peu importe" },
        ]}
        placeholder="Quel est votre budget par séance ? *"
        type="select"
        id="budget"
        value={form.budget}
        handler={(e) => {
          if (e.target.value === "Inférieur à 40 euros") {
            form.setBudget(40);
          } else if (e.target.value === "Jusqu'à 60 euros") {
            form.setBudget(60);
          } else if (e.target.value === "Jusqu'à 70 euros") {
            form.setBudget(70);
          } else if (e.target.value === "Jusqu'à 80 euros") {
            form.setBudget(80);
          } else {
            form.setBudget(5000);
          }
          console.log("budget: " + form.budget);
        }}
      ></Input>
      {form.type !== "Enfant" ? (
        <Input
          w="long"
          nature="select"
          name="lbgt"
          question="Préférez-vous un psychologue familier avec la communauté LGBT+ ?"
          options={[
            { id: "Oui", resp: "Oui" },
            { id: "Non", resp: "Non" },
            { id: "Peu importe", resp: "Peu importe" },
          ]}
          placeholder="Préférez-vous un psychologue familier avec la communauté LGBT+ ?"
          type="select"
          id="lgbt"
          value={form.lgbt}
          handler={(e) => form.setLgbt(e.target.value)}
        ></Input>
      ) : (
        <span></span>
      )}
      <Input
        w="long"
        nature="select"
        name="like"
        question="Préferez-vous un psychologue ... "
        options={[
          { id: "Homme", resp: "Homme" },
          { id: "Femme", resp: "Femme" },
          { id: "Autre", resp: "Autre" },
          { id: "Peu importe", resp: "Peu importe" },
        ]}
        placeholder="Préferez-vous un psychologue ... "
        type="select"
        id="like"
        value={form.like}
        handler={(e) => form.setLike(e.target.value)}
      ></Input>
    </div>
  );
};

export default Complementary;
