import React, { useContext } from "react";
import Input from "../../shared/components/Input";
import { FormContext } from "../../shared/context/form-context";

const Perso = (props) => {
  const form = useContext(FormContext);
  return (
    <span>
      <div className={props.step === props.lim ? "vert-wrapper" : "hidden"}>
        <p>
          Laissez nous votre mail afin que nous puissions vous transférez les
          résultats
        </p>
        <Input
          width="long"
          nature="field"
          placeholder="Mail *"
          type="text"
          handler={(e) => form.setMail(e.target.value)}
          id="mail"
          value={form.mail}
        ></Input>
        <div>
          <input
            type="checkbox"
            id="poli"
            name="poli"
            onChange={(e) => form.setValid(e.target.checked)}
          />
          <label htmlFor="poli">
            J'ai lu et accepté la{" "}
            <a
              href="https://up-care.fr/politique-de-confidentialite/"
              target="_blank"
            >
              politique de confidentialité.
            </a>
          </label>
        </div>
      </div>
    </span>
  );
};

export default Perso;
