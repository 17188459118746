import React, { useContext, useState } from "react";
import Input from "../../shared/components/Input";
import "./../Individuel/Reasons.css";
import { FormContext } from "../../shared/context/form-context";
const ReasonsE = (props) => {
  const [option, setOption] = useState(false);
  const form = useContext(FormContext);
  return (
    <div className={props.step === 2 ? "vert-wrapper" : "hidden"}>
      <h2>
        Super ! Maintenant, laissez-vous porter et faites nous confiance pour
        trouver le praticien fait pour vous
      </h2>
      <p>Pourquoi souhaitez-vous que votre enfant consulte ? *</p>
      <div className={option === false ? "hor-wrapper" : "hidden"}>
        <Input
          nature="check"
          id="Problème de gestion de ses émotions"
          name="Problème de gestion de ses émotions"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Problème de gestion de ses émotions"
        ></Input>
        <Input
          nature="check"
          id="Difficulté à créer ou maintenir des relations"
          name="Difficulté à créer ou maintenir des relations"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Difficulté à créer ou maintenir des relations"
        ></Input>
        <Input
          nature="check"
          id="Difficulté à construire des relations"
          name="Difficulté à construire des relations"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="Difficulté à construire des relations"
        ></Input>
        <Input
          nature="check"
          id="Changement de comportement"
          name="Changement de comportement"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Changement de comportement soudain"
        ></Input>
        <Input
          nature="check"
          id="Problème scolaire"
          name="Problème scolaire"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Problème scolaire"
        ></Input>
        <Input
          nature="check"
          id="Gestion d'évènements stressants ou tristes"
          name="Gestion d'évènements stressants ou tristes"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textDes="Gestion d'évènements stressants ou tristes"
        ></Input>
        <Input
          nature="check"
          id="Gestion d’un événement negatif"
          name="Gestion d’un événement negatif"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          textMob="Gestion d’un événement negatif"
        ></Input>
      </div>
      <div className={option === true ? "hor-wrapper" : "hidden"}>
        <Input
          nature="check"
          id="Difficulté à se concentrer"
          name="Difficulté à se concentrer"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Difficulté à se concentrer"
        ></Input>
        <Input
          nature="check"
          id="Manque de confiance en soi"
          name="Manque de confiance en soi"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Manque de confiance en soi"
        ></Input>
        <Input
          nature="check"
          id="Symptômes dépressifs ou anxieux"
          name="Symptômes dépressifs ou anxieux"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Symptômes dépressifs ou anxieux"
        ></Input>
        <Input
          nature="check"
          id="Gestion des peurs et phobies"
          name="Gestion des peurs et phobies"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Gestion des peurs et phobies"
        ></Input>
        <Input
          nature="check"
          id="Retards moteurs ou de communication"
          name="Retards moteurs ou de communication"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Retards moteurs ou de communication"
        ></Input>
        <Input
          nature="check"
          id="Autre"
          name="Autre"
          change={(event) => {
            form.setWhy(form.why.concat(event.target.id));
          }}
          text="Autre"
        ></Input>
      </div>
      <p
        className="other"
        onClick={(event) => {
          event.preventDefault();
          setOption(!option);
        }}
      >
        Charger de nouvelles options
      </p>
    </div>
  );
};

export default ReasonsE;
