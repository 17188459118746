import React from "react";
import "./../Individuel/Step.css";

const StepE = (props) => {
  return (
    <div className="grid-wrapper-child">
      <div className="one orange"></div>
      <div className={props.step >= 2 ? "two orange" : "two grey"}></div>
      <div className={props.step >= 3 ? "three orange" : "three grey"}></div>
      <div className={props.step >= 4 ? "four orange" : "four grey"}></div>
      <div className={props.step >= 5 ? "five orange" : "five grey"}></div>
    </div>
  );
};

export default StepE;
