import React, { useContext } from "react";
import Input from "../../shared/components/Input";
import { FormContext } from "../../shared/context/form-context";

const Adress = (props) => {
  const form = useContext(FormContext);
  return (
    <span>
      <div className={props.step === props.lim ? "vert-wrapper" : "hidden"}>
        <h2>Dans quelle zone préférez-vous consulter ?</h2>
        <p>
          Dans le cas ou vous souhaitez vous déplacer pour vos consultations
        </p>
        <Input
          width="long"
          nature="field"
          placeholder="Adresse"
          type="text"
          handler={(e) => form.setAddress(e.target.value)}
          id="address"
          value={form.address}
        ></Input>
        <div className="horizontal-wrapp">
          <Input
            width="small"
            nature="field"
            placeholder="Code postal"
            type="text"
            handler={(e) => form.setPostal(e.target.value)}
            id="postal"
            value={form.postal}
          ></Input>
        </div>
      </div>
    </span>
  );
};

export default Adress;
