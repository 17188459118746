import React, { useContext } from "react";
import Input from "../../shared/components/Input";
import { FormContext } from "../../shared/context/form-context";

const PersonalC = (props) => {
  const form = useContext(FormContext);
  return (
    <span>
      <div className={props.step === 1 ? "vert-wrapper" : "hidden"}>
        <h2>
          Notre objectif est de comprendre votre besoin pour y répondre au mieux
        </h2>
        <p>
          Pas de panique ça ne prendre que 5 min et vos réponses seront ensuite
          directement supprimées pour protéger votre intimité
        </p>
        <Input
          width="long"
          nature="field"
          placeholder="Mail *"
          type="text"
          handler={(e) => form.setMail(e.target.value)}
          id="mail"
          value={form.mail}
        ></Input>
        <Input
          width="long"
          nature="field"
          placeholder="Adresse"
          type="text"
          handler={(e) => form.setAddress(e.target.value)}
          id="address"
          value={form.address}
        ></Input>
        <div className="horizontal-wrapp">
          <Input
            width="small"
            nature="field"
            placeholder="Code postal *"
            type="text"
            handler={(e) => form.setPostal(e.target.value)}
            id="postal"
            value={form.postal}
          ></Input>
          <Input
            width="small"
            nature="field"
            placeholder="Age"
            type="number"
            handler={(e) => form.setYears(e.target.value)}
            id="years"
            value={form.years}
          ></Input>
          <Input
            nature="select"
            name="Sexe"
            question="Êtes-vous ? *"
            options={[
              { id: "Femme", resp: "Femme" },
              { id: "Homme", resp: "Homme" },
              { id: "Autre", resp: "Autre" },
              { id: "Préfère ne pas dire", resp: "Préfère ne pas dire" },
            ]}
            placeholder="Sexe *"
            type="select"
            handler={(e) => form.setSexe(e.target.value)}
            id="sexe"
            value={form.sexe}
          ></Input>
        </div>
        <Input
          w="long"
          nature="select"
          name="couple"
          question="Depuis combien de temps êtes-vous en couple ? *"
          options={[
            { id: "Moins de 2 ans", resp: "Moins de 2 ans" },
            { id: "Entre 2 et 5 ans", resp: "Entre 2 et 5 ans" },
            { id: "Entre 6 et 10 ans", resp: "Entre 6 et 10 ans" },
            { id: "Entre 11 et 15 ans", resp: "Entre 11 et 15 ans" },
            { id: "Plus de 15 ans", resp: "Plus de 15 ans" },
          ]}
          placeholder="Depuis combien de temps êtes-vous en couple ? *"
          type="select"
          id="couple"
          value={form.dure}
          handler={(e) => form.setDure(e.target.value)}
        ></Input>
      </div>
    </span>
  );
};

export default PersonalC;
