import React, { useContext } from "react";
import "./Consulted.css";
import Input from "../../shared/components/Input";
import { FormContext } from "../../shared/context/form-context";
const Consulted = (props) => {
  const form = useContext(FormContext);
  return (
    <div className={props.step === 4 ? "vert-wrapper" : "hidden"}>
      <p>Quelles sont vos attentes ? *</p>
      <div className="horiz-wrapper">
        <Input
          nature="check"
          id="Parler à quelqu'un"
          name="Parler à quelqu'un"
          change={(event) => {
            form.setWait(form.wait.concat(event.target.id));
          }}
          text="Parler à quelqu'un"
        ></Input>
        <Input
          nature="check"
          id="Explorer mon passé"
          name="Explorer mon passé"
          change={(event) => {
            form.setWait(form.wait.concat(event.target.id));
          }}
          text="Explorer mon passé"
        ></Input>
        <Input
          nature="check"
          id="M'aider à me remettre en question"
          name="M'aider à me remettre en question"
          change={(event) => {
            form.setWait(form.wait.concat(event.target.id));
          }}
          text="M'aider à me remettre en question"
        ></Input>
        <Input
          nature="check"
          id="Soulager des émotions difficiles"
          name="Soulager des émotions difficiles"
          change={(event) => {
            form.setWait(form.wait.concat(event.target.id));
          }}
          text="Soulager des émotions difficiles"
        ></Input>
        <Input
          nature="check"
          id="Fixer des objectifs et les réaliser"
          name="Fixer des objectifs et les réaliser"
          change={(event) => {
            form.setWait(form.wait.concat(event.target.id));
          }}
          text="Fixer des objectifs et les réaliser"
        ></Input>
        <Input
          nature="check"
          id="Autre"
          name="Autre"
          change={(event) => {
            form.setWait(form.wait.concat(event.target.id));
          }}
          text="Autre"
        ></Input>
        <Input
          nature="check"
          id="Je n'en ai pas"
          name="Je n'en ai pas"
          change={(event) => {
            form.setWait(form.wait.concat(event.target.id));
          }}
          text="Je n'en ai pas"
        ></Input>
      </div>
    </div>
  );
};

export default Consulted;
